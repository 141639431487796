.payment-page {
	background-color: #f6f6f6;
}

.site-content__payments {
	.container {
		max-width: 565px;
		padding: 0;
		@media only screen and (max-width: 667px) {
			width: 95%;
		}
	}
	.account-summary-block {
		margin: 25px 0 20px;
		padding: 25px;
		@include border-radius(5px);
		background-color: $white;
		span {
			line-height: 1;
			&.plus-minus {
				font-size: 60px;
				font-weight: $med;
			}
		}
		&__amount {
			&--pound {
				color: $main-font;
				font-weight: $reg;
				font-size: 40px;
			}
			&--price {
				font-size: 60px;
				color: $brand-blue;
				font-weight: $f-black;
			}
		}
	}
	&--title {
		color: #4a4e6b;
		font-size: 28px; 
		line-height: 1;
		font-weight: $f-black;
		margin: 0 0 40px;
		text-align: center;
	}
	&--methods {
		padding: 40px 0;
		&.active {
			@include rel;
		}
		#continue-button {
			display: none;
			&.active {
				display: block;
			}
		}
		> .circle-loader {
			display: none;
			position: absolute;
			top: 50%;
			right: 0;
			bottom: 0;
			left: 50%;
			z-index: 11;
			transform: translate(-50%, -50%);
			margin: 0;
			&.active {
				display: block !important;
			}
			&--top {
				top: 200px;
			}
		}
		.container {
			max-width: 1145px;
			@media only screen and (max-width: 1275px) {
				width: 95%;
			}
			.btn {
				span {
					line-height: 1.5;
				}
				&.left svg {
					float: left;
					margin: 0 10px 0 0;
				}
				&.right svg {
					float: right;
					color: $white;
				}
				svg {
					font-size: 30px;
				}
			}
		}
		&--wrapper {
			@include border-radius(5px);
			background-color: $white;
			float: left;
			width: 48.5%;
			margin: 0 3% 40px 0;
			padding: 70px 40px;
			@include rel;
			cursor: pointer;
			@include transition(.4s);
			&.last-row {
				margin: 0 0 40px;
			}
			&.active {
				background-color: $brand-green;
				.payment-label {
					color: $white;
				}
				> div {
					svg {
						color: $brand-green;
						background-color: $white;
					}
				}
				.payment-checkmark {
					visibility: visible;
					opacity: 1;
				}
			}
			span {
				&.payment-label {
					@include inline;
					font-weight: $f-black;
					color: $brand-blue;
					font-size: 24px;
					margin: 0 0 0 10px;
					@include transition(.4s);
				}
				&.payment-checkmark {
					position: absolute;
					top: -10px;
					left: -10px;
					z-index: 2;
					visibility: hidden;
					opacity: 0;
				}
			}
			input[type="radio"] {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				height: 100%;
				width: 100%;
				opacity: 0;
			}
			> div {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 2;
				@include inline;
				pointer-events: none;
				width: 260px;
				> svg {
					color: $white;
					background-color: $brand-blue;
					height: 80px;
					width: 80px !important;
					@include border-radius(50%);
					padding: 20px;
				}
				span, 
				svg {
					vertical-align: middle;
					@include transition(.4s);
					pointer-events: none;
				}
			}
			@media only screen and (max-width: 768px) {
				width: 100%;
				float: none;
				margin: 0 auto 20px !important;
			}
		}
		@media only screen and (max-width: 768px) {
			.container-fluid.pl-0.pr-0 {
				.btn {
					width: 100% !important;
					float: none !important;
					min-width: 100% !important;
				}
			}
		}
	}
	&--confirmation {
		padding: 80px 0;
		.container {
			max-width: 565px;
			@media only screen and (max-width: 667px) {
				width: 95%;
			}
		}
		.shadow {
			background-color: $white;
			@include border-radius(5px);
		}
		&--success {}
		&--fail {
			.payment-checkmark {
				background-color: #d95d39;
				&__inner {
					background-color: #d95d39;
				}
			}
			h4 {
				color: #d95d39 !important;
			}
		}
		.site-content__payments--title {
			margin: 0 0 80px;
		}
	}
	&--block {
		padding: 100px 35px 30px;
		@include rel;
		.payment-checkmark {
			position: absolute;
			top: -40px;
			left: 50%;
			margin-left: -40px;
			border-width: 9px;
			padding-top: 14px;
			height: 80px;
			width: 80px;
			&__inner {
				padding: 5px;
			}
		}
		&--inner {
			max-width: 350px;
			margin: 0 auto;
			text-align: center;
			.left, 
			.right {
				width: 50%;
			}
			h3, 
			h4, 
			h5 {
				line-height: 1;
			}
			h3 {
				font-size: 1.75rem;
				margin: 0 0 30px;
			}
			h4 {
				font-size: 1.5rem;
				color: $brand-blue;
			}
			h5 {
				color: $black;
				font-size: 1.125rem;
			}
			.btn-holder {
				padding-top: 40px;
				clear: both;
				.btn--green {
					margin-bottom: 20px;
				}
			}
		}
	}
}
.payment-checkmark {
	background-color: $brand-green;
	padding-top: 6px;
	border: 2px solid $white;
	@include border-radius(50%);
	@include inline;
	height: 50px;
	width: 50px;
	transition: visibility 0.4s linear, opacity 0.4s linear;
	text-align: center;
	&__inner {
		background-color: $brand-green;
		padding: 5px 5px 6px;
		border: 2px solid $white;
		@include border-radius(50%);
		height: 35px;
		width: 35px;
		display: block;
		margin: 0 auto;
	}
	svg {
		color: $white;
		height: 20px;
		width: 20px !important;
	}
}

//Card Payment Page - Card Picker
.card__payment {
	padding: 1.5rem;
	.col-6 {
		padding: 0 20px;
	}
	.cardTable > table {
		background-color: $white;
	}
	.accordions__payments {
		margin: 30px auto 0;
	}
	.accordions__block {
		padding: 20px 20px 20px 0;
	}
	#paymentBox {
		button:disabled {
		  cursor: not-allowed;
		  pointer-events: all !important;
		}
	}
	.inputWrap {
		margin: 0 0 20px;
	}
	.select-container select {
		width: 100%;
		background-color: $white;
		color: $main-font;
		padding: 0 20px;
		height: 50px;
		border: 1px solid #ccc;
	}
	td {
		width: 50%;
		padding: 10px;
		border: 1px solid #ccc;
		text-align: center;
	}
	.cardImg > img {
		width: 100px;
		height: auto;
		margin: 0 auto;
	}
	@media only screen and (max-width: 1024px) {
		.row {
			display: block;
			.col-6 {
				width: 100%;
			}
		}
		table {
			width: 100%;
		}
	}
}






