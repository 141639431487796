.blackbell-loading {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    background-color: rgba(33, 33, 33, 0.9);
    height: 100vh;
    width: 100%;
    display: none;
    text-align: center;
    color: #fff;


    &__inner {
        height: 100vh;
        img {
            height: 30vh;
            width: auto;
        }
        p {
            font-size: 30px;
        }
    }
}
.loading-div {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    background-color: rgba(33, 33, 33, 0.9);
    height: 100vh;
    width: 100%;
    //display: none;
    text-align: center;
    color: #fff;
    > .circle-loader {
        position: absolute;
        top: 50%;
        right: 0;
        bottom: 0;
        left: 50%;
        z-index: 11;
        transform: translate(-50%, -50%);
        margin: 0;
        &.active {
            display: block !important;
        }
        &--top {
            top: 200px;
        }
    }
}
