/* Forms */
.input-group-append,
.input-group-prepend {
  background: color(white);
  color: $input-placeholder-color;
  width: 100%;
  border: none;

  .input-group-text {
    background: transparent;
    border-color: $border-color;
    padding: 0.5rem !important;
  }
}
.select2-container--default .select2-selection--single {
  border: none;
}

//Login Page Form Validation/Error Message Styling
.login-page {
  .parsley-error {
    &.form-control {
      margin: 0;
      border-color: $brand-red;
    }
  }
  .alerts {
    padding: 0;
    &__icon {
      padding: 10px 0;
    }
    &__content {
      //padding: 5px 40px 5px 5px;
      h6 {
        font-size: 13.6px;
        line-height: 1.3;
      }
    }
  }
}
.full-width-form {
  > div {
    width: 100%;
    padding: 0;
    max-width: 100%;
  }
}

.statement-form {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    button h4 {
      text-transform: none;
    }
}

//changing all show templates as a list instead of inputs
.list__show {
  .list__half {
      width: 49%;
      margin-bottom: 20px;
      @media only screen and (max-width: 768px) {
        float: none !important;
        width: 100%;
        min-height: 0 !important;
        height: auto;
      }
  }
  &--roles {
    ul {
      list-style-type: none !important;
      padding-left: none !important;
      li {
        margin: 0 0 10px !important;
      }
    }
  }
  ul {
    @include no-format;
  }
  li {
    margin: 0 0 20px;
    &.last-list-item {
      border: none;
    }
    &:last-child {
      margin: 0;
    }
  }
  .form-group {
    margin: 0;
  }
  textarea.form-control {
    margin-top: 20px;
  }
  .form-control {
    border: none;
  }
  label.control-label {
    font-weight: $bold;
    color: $brand-blue !important;
  }
}

.checkboxes {
  @include rel;
  margin: 0 0 15px;
  padding: 5px 0 0;
  width: 33.33%;
  float: left;
  @media only screen and (max-width: 768px) {
    width: 48%;
  }
  @media only screen and (max-width: 479px) {
    float: none;
    width: 100%;
  }
  label {
    padding: 0 0 0 40px;
  }
}

label {
  color: $brand-purple !important;
  font-size: 1rem;
  font-weight: $bold;
}

.custom__checkbox:checked + label::before,
.custom__radio:checked + label::before {
  content: "";
  border-color: $brand-blue;
}
.custom__checkbox:checked + label::after,
.custom__radio:checked + label::after {
  content: "";
  height: 15px;
  width: 15px;
  @include border-radius(50%);
  background-color: $brand-blue;
  position: absolute;
  top: 6px;
  left: 8px;
}
.custom__checkbox,
.custom__radio {
  display:none;
}
.custom__checkbox + label::before,
.custom__radio + label::before {
  content: "";
  @include inline;
  border: solid 1px #c4c4c4;
  height: 30px;
  width: 30px;
  background-color: #ffffff;
  position: absolute;
  top: -1px;
  left: 0;
}

.parsley-errors-list {
  @include no-format;
  li {
    color: $brand-red;
  }
}
select {
  -webkit-appearance: none;
  -moz-appearance:    none;
  appearance:         none;
}

//.form__search {
.form__search {
  @include rel;
  //padding: 10px;
  .input-group {
    &-btn {
      float: right;
    }
  }
  input[type="text"] {
    float: left;
    border: 1px solid #cccccc;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    background-color: #efefef;
    padding: 8px 15px;
    height: 38px;
  }
  button[type="submit"] {
    border: 1px solid $brand-blue;
    background-color: $brand-blue;
    border-radius: 0px;
    @include transition(.4s);
    color: $white;
    font-size: 12px;
    padding: 18px 16px;
    height: 38px;
    cursor: pointer;
    @include on-event {
      background-color: $white;
      color: $brand-blue;
    }
  }
  button[type="button"] {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    @include transition(.4s);
    color: $white;
    font-size: 12px;
    padding: 18px 16px;
    height: 38px;
    cursor: pointer;
  }
}

.select2-container {
  width: 100% !important;
}

.select-container {
  margin: 0 0 20px;
  @include rel;
  &:before,
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    pointer-events: none;
  }
  &:before {
    height: 100%;
    width: 35px;
    background-color: #e6e1e1;
    top: 0;
    right: 0;
  }
}
.form-control {
  border: 1px solid $border-color;
  font-family: $type-1;
  font-size: $default-font-size;
  padding: $btn-padding-y .75rem;
  line-height: 14px;
  font-weight: 400;

  &.form-control-lg {
    padding: $input-btn-padding-y-lg .75rem;
  }

  &.form-control-sm {
    padding: $input-btn-padding-y-sm .75rem;
  }
}

select {
  &.form-control {
    padding: 0.4375rem 0.75rem;
  }

}

.form-group {
  @include rel;
  @include cf;
  small {
    @include inline;
    vertical-align: top;
    margin: -3px 0 0.5rem;
  }
  label {
    color: $main-font !important;
    &.required {
      &:after {
        content: ' (Required)';
        color: #aaaaaa;
        font-size: 0.7rem;
      }
    }
  }
  &__inner {
    @include inline;
    width: 100px;
    margin: 0 10px 0 0;
    &--last {
      margin: 0;
    }
    span {
      float: left;
      margin: 5px 0 0 35px;
      &.sup {
        margin: -3px 0 0 35px;
      }
    }
    label.left {
      font-size: 0;
    }
  }
}

//file upload styling
.file__custom::-webkit-file-upload-button {
  visibility: hidden;
}
.file__custom {
  &.form-control {
    border: none;
    padding: 0;
  }
  @include on-event {
    &:before {
      background-color: $white;
      color: $brand-blue;
    }
  }
  &::before {
    content: attr(data-content);
    @include inline;
    background-color: $brand-blue;
    color: $white;
    border: 1px solid $brand-blue;
    border-radius: 5px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    @include transition(.4s);
    width: 100%;
    text-align: center;
  }
}

input[type="text"],
input[type="tel"],
input[type="email"],
textarea, 
input[type="password"],
input[type="url"],
input[type="number"] {
  width: 100%;
  @include border-radius(5px);
  border-radius: 5px !important;
  background-color: $white;
  color: $main-font;
  padding: 0rem 1.75rem 0rem 0.75rem;
  height: 38px;
  border: 1px solid $brand-blue !important;
}

.input-group {
  border: 1px solid $brand-blue;
  input {
    border: none;
  }
}

div.daterange_picker {
  width: 100%;
  @include border-radius(5px);
  background-color: $white;
  color: $main-font;
  padding: 0 20px;
  border: 1px solid $brand-blue;
  svg {
  }
  span {
  }
}

.card {
  .col-6 {
    padding: 0;
    width: 100%;
    display: block;
    max-width: 100%;
  }
}
.form-group {
  label {
    font-size: 1rem;
    line-height: 1;
    vertical-align: top;
    display: block;
  }

  &.has-danger {
    .form-control {
      border-color: theme-color(danger);
    }
  }

  .file-upload-default {
    visibility: hidden;
    position: absolute;
  }

  .file-upload-info {
    background: transparent;
  }
}

label.required:after {
  content: ' (Required)';
  color: #aaaaaa;
  font-size: 0.9rem;
}

label.error {
  margin:0 !important;
}

.form-inline {
  .select-container {
    margin-right:0.5rem !important;
  }
}

/* === Select2 Bootstrap append/prepend integration === */


div.input-group-prepend .select2-container--rmg .select2-selection--single {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
div.input-group-append .select2-container--rmg .select2-selection--single {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

/* === Select2 Overide theme to match other form inputs === */

.select2-dropdown {
  border: 1px solid #f2f2f2 !important;
}

.select2-container--rmg .select2-selection--single {
  background-color: #fff;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  height:38px;}
.select2-container--rmg .select2-selection--single .select2-selection__rendered {
  color: #495057;
  line-height: 18px;
  padding: 0rem 1.75rem 0rem 0.75rem;}
.select2-container--rmg .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold; }
.select2-container--rmg .select2-selection--single .select2-selection__placeholder {
  color: #999; }
.select2-container--rmg .select2-selection--single .select2-selection__arrow {
  height: 36px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px; }
.select2-container--rmg .select2-selection--single .select2-selection__arrow b {
  border-color: $brand-blue transparent transparent transparent;
  border-style: solid;
  border-width: 7px 6px 0 6px;
  height: 0;
  left: 50%;
  margin-left: -12px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
  z-index:2
}

.add-arrow {
  &--added {
    @include rel;
    &::after {
      content: "";
      border-color: $brand-blue transparent transparent transparent;
      border-style: solid;
      border-width: 7px 6px 0 6px;
      height: 0;
      position: absolute;
      top: 17px;
      right: 11px;
      width: 0;
      z-index:2
    }
    > .select-container {
      margin: 0;
    }
  }
}

.select2-container--rmg[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--rmg[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--rmg.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }
.select2-container--rmg.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none; }

.select2-container--rmg.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent $brand-blue transparent;
  border-width: 0 6px 7px 6px; }

.select2-container--rmg .select2-selection--multiple {
  background-color: white;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  cursor: text;
  line-height: 38px;
}
.select2-container--rmg .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%; }
.select2-container--rmg .select2-selection--multiple .select2-selection__rendered li {
  list-style: none; }
.select2-container--rmg .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left; }
.select2-container--rmg .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px; }
.select2-container--rmg .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  padding: 0 5px; }
.select2-container--rmg .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }
.select2-container--rmg .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333; }

.select2-container--rmg[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--rmg[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--rmg[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right; }

.select2-container--rmg[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--rmg[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--rmg.select2-container--focus .select2-selection--multiple {
  border: 1px solid #f2f2f2;
  outline: 0; }

.select2-container--rmg.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

.select2-container--rmg.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

.select2-container--rmg.select2-container--open.select2-container--above .select2-selection--single, .select2-container--rmg.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--rmg.select2-container--open.select2-container--below .select2-selection--single, .select2-container--rmg.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--rmg .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa; }

.select2-container--rmg .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; }

.select2-container--rmg .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--rmg .select2-results__option[role=group] {
  padding: 0; }

.select2-container--rmg .select2-results__option[aria-disabled=true] {
  color: #999; }

.select2-container--rmg .select2-results__option[aria-selected=true] {
  background-color: #ddd; }

.select2-container--rmg .select2-results__option .select2-results__option {
  padding-left: 1em; }
.select2-container--rmg .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0; }
.select2-container--rmg .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em; }
.select2-container--rmg .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em; }
.select2-container--rmg .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em; }
.select2-container--rmg .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em; }
.select2-container--rmg .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em; }

.select2-container--rmg .select2-results__option--highlighted[aria-selected] {
  background: $blue-gradient-light;
  transition: background 0.25s ease;
  -webkit-transition: background 0.25s ease;
  -moz-transition: background 0.25s ease;
  -ms-transition: background 0.25s ease;
  color: white; }

.select2-container--rmg .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

  input[type=number] {
    -webkit-appearance: textfield;
       -moz-appearance: textfield;
            appearance: textfield;
  }
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
  }

.announcement-date:checked + label {
  color: #ffffff   !important;
}

.announcement-date:checked:hover + label {
  color: #0aab77 !important;
}
