/* Tables */
.table {
  @include border-radius(5px);
  .btn {
    margin: 2px;
  }
  thead {
    tr {
      background-color: $brand-blue;
      border: 1px solid $brand-blue;
      th {
        font-weight: $f-black;
        color: $white;
        border-right: 1px solid #d4d4d4;
        font-size: 16px;
      }
    }
    th {
      border-top: 0;
      border-bottom-width: 1px;
      font-family: $type-2;
      font-weight: 500;
      padding: 10px;
      vertical-align: middle;
      svg {
        margin-left: 0.325rem;
      }
    }
    tr.hidden {
      background-color: transparent;
      border: none;
      th.visible {
        background-color: $brand-blue;
      }
      th.hidden {
        background-color: transparent;
        border: none;
      }
    }

  }
  tbody {
    background-color: $white;
    td {
      border: 1px solid #d4d4d4;
      padding: 10px;
    }
  }
  td,
  th {
    vertical-align: middle;
    font-size: $default-font-size;
    line-height: 1;
    padding: 5px 10px;
    img {
      @extend .img-ss;
      border-radius: 100%;
    }
    .badge {
      margin-bottom: 0;
    }
    .form-check,
    .form-radio {
      margin-top: 0;
      margin-bottom: -0px;
    }
  }
  
  
  td.info, td.edit, td.archive {
    width:80px;
    text-align:center;

  }
  td.info-edit, td.info-archive, td.edit-archive {
    width:130px;
    text-align:center;
  }
  td.info-edit-archive, td.restore-delete, td.create-read-edit-delete {
    width:200px;
    text-align:center;
  }
  td.info-edit-archive-invite {
    width:260px;
    text-align:center;
  }
  tr.title {
    td {
      background-color: $brand-grey;
      font-family: $type-2;
      font-weight: $f-black;
      padding:10px;
      p {
        font-family: $type-2;
        font-weight: $f-black;
      }
    }
  }
  tr.total {
    td {
      background-color: $brand-blue;
      font-family: $type-2;
      font-weight: $f-black;
      padding:10px;
      p {
        font-family: $type-2;
        font-weight: $f-black;
      }
    }
  }
}

.table_responsive_sidebar {
  width: 95% !important;
}

.total-amount-container {
  background-color: $brand-blue;
  .total {
    font-family: $type-2;
    font-weight: $f-black;
    padding:10px;
    color: $white;
    font-size: 16px;
  }
}
