.sidebar__hidden {
	width: 100%;
	background-color: $white;
	position: absolute;
	top: 112px;
	right: 0;
	opacity: 0;
	transition: opacity 0.4s ease-in-out;
	z-index: -1;
	&.active {
		opacity: 1;
		z-index: 50;
		box-shadow: 0px 1px 3px 0px rgba(75, 79, 109, 0.5);
	}
	.btn__large {
		min-width: 100%;
	}
	&--inner {
		height: calc(100vh - 112px);
    	overflow-y: auto;
    	padding: 60px 30px;
    	.btn {
    		display: block;
    		width: 100%;
    	}
	}
	&--intro {
		margin: 0 0 50px;
		* {
			@include inline;
			vertical-align: middle;
		}
		h3 {
			color: #555555;
			font-size: 20px;
			line-height: 1;
			margin: 0;
			font-weight: $f-black;
		}
	}
	&--intro-btn {
		width: 60px;
		@include border-radius(5px);
		text-transform: uppercase;
		color: $white;
		padding: 5px;
		background-color: $brand-blue;
		border: 1px solid $brand-blue;
		margin: 0 0 0 20px;
		font-size: 12px;
		line-height: 1;
		font-weight: $bold;
		text-align: center;
		@include on-event {
			color: $brand-blue;
			background-color: $white;
			text-decoration: none;
			svg {
				color: $brand-blue;
			}
		}
		svg {
			color: $white;
			@include transition(.4s);
			float: right;
		}
	}
	&--notification {
		min-height: 20px;
		min-width: 20px;
		@include border-radius(50%);
		text-align: center;
		color: $white;
		background-color: $brand-blue;
		font-size: 12px;
		line-height: 1;
		padding-top: 4px;
	}
	&--block {
		border: 1px solid #efefef;
		@include border-radius(5px);
		padding: 20px 45px 25px 50px; 
		margin: 0 0 20px;
		@include rel;
		&:last-child {
			margin: 0;
		}
		svg {
			position: absolute;
			top: 0;
			left: -18px;
			height: 36px;
			width: 36px !important;
			@include border-radius(50%);
			text-align: center;
			color: $white;
			font-size: 12px;
			padding: 10px;
		}
		h4 {
			font-size: 14px;
			line-height: 1;
			color: $brand-blue;
			margin: 0 0 15px;
			font-weight: $f-black;
		}
		p {
			color: #4b4f6d;
			font-size: 14px;
			line-height: 1.4;
		}
	}
	&--document svg {
		background-color: $brand-purple;
	}
	&--report svg {
		background-color: $brand-red;
	}
	&--block-btn {
		position: absolute;
		right: 0;
		bottom: -1px;
		color: $white;
		background-color: $brand-green;
		border: 1px solid $brand-green;
		text-transform: uppercase;
		font-size: 12px;
		line-height: 1;
		display: block;
		border-top-left-radius: 5px;
		border-bottom-right-radius: 5px;
		padding: 5px;
		@include on-event {
			text-decoration: none;
			background-color: $white;
			color: $brand-green;
		}
	}
	&--settings-block {
		padding: 15px 25px 10px 20px;
		&.radio {
			padding: 15px 25px 30px 20px;
		}
		&.no-tick .block-checked {
			display: none !important;
		}
		&-check {
			@include rel;
			> div {
				@include inline;
				padding: 0 0 0 21px;
				@include rel;
				&#opt_out_of_paperless,
				&.form-group {
					display: block;
					padding: 0;
				}
			}
			.sidebar__hidden--checkbox:checked + label::before,
			.sidebar__hidden--checkbox-only:checked + label::before {
				content: "";
				border-color: $brand-blue;
			}
			.sidebar__hidden--checkbox:checked + label::after,
			.sidebar__hidden--checkbox-only:checked + label::after {
				content: "";
				height: 10px;
				width: 10px;
				@include border-radius(50%);
				background-color: $brand-blue;
				position: absolute;
				top: 5px;
				left: 5px;
			}
			.sidebar__hidden--checkbox,
			.sidebar__hidden--checkbox-only {
				display:none;
			}
			.sidebar__hidden--checkbox + label::before,
			.sidebar__hidden--checkbox-only + label::before {
				content: "";
				@include inline;
				border: solid 1px transparent;
				height: 20px;
				width: 20px;
				background-color: #eeeeee;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
		.radio-wrapper {
			margin: 0 0 10px;
			@include rel;
			padding: 0 0 0 30px;
			display: block
		}
		.checkmark {
		    width: 22px;
		    height: 22px;
		    @include rotate(45deg);
		    border: 1px solid $brand-green;
		    @include border-radius(50%);
		    position: absolute;
		    top: 5px;
		    right: 5px;
		    display: none;
		    &.block-checked {
				display: block;
			}
		    &:before, 
		    &:after {
		    	content: "";
			    position: absolute;
			    background-color: $brand-green;
		    }
		    &:before {
			    width: 2px;
			    height: 10px;
			    left: 10px;
			    top: 5px;
			}

			&:after {
			    width: 3px;
			    height: 2px;
			    left: 7px;
			    top: 13px;
			}
		}
		input[type="radio"]:checked + label::before {
			content: "";
			border-color: $brand-blue;
		}
		input[type="radio"]:checked + label::after {
			content: "";
			height: 10px;
			width: 10px;
			@include border-radius(50%);
			background-color: $brand-blue;
			position: absolute;
			top: 5px;
			left: 5px;
		}
		input[type="radio"] {
			display:none;
		}
		input[type="radio"] + label::before {
			content: "";
			@include inline;
			border: solid 1px transparent;
			height: 20px;
			width: 20px;
			background-color: #eeeeee;
			position: absolute;
			top: 0;
			left: 0;
		}
		h4 {
			@include inline;
			text-transform: uppercase;
			margin: 0 0 10px;
		}
	}
}