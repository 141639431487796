.unit__select {
	#payment-form {
		.container-fluid.pl-0.pr-0 {
			@include rel;
		}
		label {
			padding: 0 0 0 15px;
		}
	}
	input[type="checkbox"]:checked + label::before {
		content: "";
		border-color: $brand-green;
	}
	input[type="checkbox"]:checked + label::after {
		content: "";
		height: 10px;
		width: 10px;
		@include border-radius(50%);
		background-color: $brand-green;
		position: absolute;
		top: 5px;
		left: 9px;
		pointer-events: none;
	}
	input[type="checkbox"] {
		opacity: 0;
		float: left;
		height: 20px;
		width: 20px;
	}
	input[type="checkbox"] + label::before {
		content: "";
		@include inline;
		border: solid 1px transparent;
		height: 20px;
		width: 20px;
		background-color: #eeeeee;
		margin-left: -31px;
		float: left;
	}
}

.site-content__statements {
	> .container {
		max-width: 1145px;
	}
	.account-summary-block {
		.btn {
			display: block;
			max-width: 390px;
			margin: 30px auto 0;
		}
	}
	&--info-blocks {
		padding: 20px 0;
		.container {
			max-width: 1145px;
		}
		.block-title {
			text-align: center;
			padding: 0 20px;
			margin: 0 0 30px;
		}
		.shadow {
			padding: 30px 0;
			@include border-radius(5px);
			background-color: $white;
			&.col-6 {
				width: 48%;
			}
			&.left {
				margin: 0 2% 0 0;
			}
		}
		@media only screen and (max-width: 1024px) {
			.container {
				display: block !important;
			}
			.shadow.col-6 {
				width: 100% !important;
				margin: 0 auto 20px !important;
			}
		}
	}
}

.button__block {
	@media only screen and (max-width: 479px) {
		display: block !important;
		.col-6 {
			width: 100%;
			margin: 0 auto 10px;
			.btn {
				display: block;
				width: 100%;
			}
		}
	}
}

//Statement list styling
.list__arrow {
	background-color: $white;
	@include no-format;
	li {
		padding: 15px 30px;
		font-size: 16px;
		a {
			color: $brand-blue;
			font-size: 16px;
			font-weight: $f-black;
			text-decoration: underline;
			@include on-event {
				text-decoration: none;
			}
		}
		.blue-text {
			color: $brand-blue;
		}
		.green-text {
			color: $brand-green;
		}
		span {
			> span {
				font-weight: $f-black;
				font-size: 24px;
			}
			svg {
				margin: 0 10px 0 0;
				vertical-align: middle;
				&.fa-arrow-circle-up,
				&.fa-check-circle {
					color: $brand-green;
				}
				&.fa-arrow-circle-down {
					color: $brand-red;
				}
			}
		}
	}
	@media only screen and (max-width: 479px) {
		text-align: center;
		svg {
			display: block;
			margin: 0 auto 10px !important;
		}
		.right {
			float: none;
			display: block;
		}
	}
}
