/* Modals */
.modal {
  .modal-dialog {
    margin-top: calc(#{$navbar-height} + 30px);
    max-width: 75% !important;
    width: 100%;
    &.modal-dialog-centered {
      margin-top: 0px !important;
    }

    .modal-content {
      &__chat {
        max-height: 85vh;
        height: 85vh;
        overflow: scroll;
      }
      .modal-header {
        padding: $modal-header-padding-y $modal-header-padding-x;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        color: $brand-blue;
        text-transform: capitalize;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        white-space: nowrap;
        padding: 1.3rem 1.81rem;
        background: $brand-purple;
        .modal-title {
          color: $white;
        }
        h5 {
          font-size: 1.25rem;
        }
        p {
          font-weight: $semi;
        }
        button.close {
          font-size: 2rem;
          font-weight: 400;
          color: $white;
          text-shadow: none;
          opacity: 1;
        }
      }

      .modal-body {
        padding: $modal-body-padding-y $modal-body-padding-x;
        background: $white;
        &__chat {
          height: 75vh;
        }

      }

      .modal-footer {
        padding: $modal-footer-padding-y $modal-footer-padding-x;
      }
    }
  }
}
