/* Navbar */

.navbar {
  &.default-layout {
    font-family: $type-2;
    background: $blue-gradient;
    transition: background $action-transition-duration $action-transition-timing-function;
    -webkit-transition: background $action-transition-duration $action-transition-timing-function;
    -moz-transition: background $action-transition-duration $action-transition-timing-function;
    -ms-transition: background $action-transition-duration $action-transition-timing-function;

    .navbar-brand-wrapper {
      transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      background: $sidebar-light-bg;

      .sidebar-dark & {
        background: $sidebar-dark-bg;
      }

      width: $sidebar-width-lg;
      height: $navbar-height;

      .navbar-brand {
        color: $white;
        font-size: 1.5rem;
        line-height: 48px;
        margin-right: 0;
        padding: 0.25rem 0;
        display: flex;


        &:active,
        &:focus,
        &:hover {
          color: lighten(color(gray-dark), 10%);
        }

        img {
          width: calc(#{$sidebar-width-lg} - 130px);
          margin: auto;
          vertical-align: middle;
          max-width:95px;
          max-height:40px;
          width: auto;
          height: auto;

        }
      }

      .brand-logo-mini {
        display: none;

        img {
          width: calc(#{$sidebar-width-icon} - 40px);
          max-width: 100%;
          margin: auto;
        }
      }
    }

    .navbar-menu-wrapper {
      transition: width $action-transition-duration $action-transition-timing-function;
      -webkit-transition: width $action-transition-duration $action-transition-timing-function;
      -moz-transition: width $action-transition-duration $action-transition-timing-function;
      -ms-transition: width $action-transition-duration $action-transition-timing-function;
      color: $white;
      padding-left: 15px;
      padding-right: 15px;
      width: calc(100% - #{$sidebar-width-lg});
      height: $navbar-height;

      @media (max-width: 991px) {
        width: auto;
      }

      .navbar-toggler {
        border: 0;
        color: inherit;

        &:not(.navbar-toggler-right) {
          @media (max-width: 991px) {
            display: none;
          }
        }
      }

      .navbar-text {
        font-size: $default-font-size;
      }

      .navbar-nav {
        flex-direction: row;
        align-items: center;

        .nav-item {
          margin-left: 1rem;
          margin-right: 1rem;

          .nav-link {
            color: inherit;
            font-size: $navbar-font-size;
            vertical-align: middle;

            @media (max-width: 767px) {
              margin-left: 0.5rem;
              margin-right: 0.5rem;
            }

            i {
              font-size: $navbar-icon-font-size;
              vertical-align: middle;
            }

            .profile-text {
              margin-right: 15px;

              .rtl & {
                margin-right: 0;
                margin-left: 15px;
              }
            }

            &.nav-btn {
              .btn {
                background: rgba($white, .1);
                padding: 0.75rem 1rem;
                color: $white;
              }

              &:after {
                display: none;
              }
            }
          }

          &.color-setting {
            i {
              font-size: 25px;
              vertical-align: text-top;
            }
          }
        }

        &.navbar-nav-right {
          @media (min-width: 992px) {
            margin-left: auto;

            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
          }
        }

        &.header-links {
          height: $navbar-height;
          padding-left: 2%;

          .nav-item {
            margin: 0;

            .nav-link {
              height: $navbar-height;
              font-size: $navbar-font-size;
              padding: 16px 25px;
              display: flex;
              align-items: center;

              i {
                margin-right: 10px;
                font-size: 21px;

                .rtl & {
                  margin-right: 0;
                  margin-left: 10px;
                }
              }
            }

            &.active {
              background: rgba($white, 0.13);
            }
          }
        }
      }
    }

    //navbar color variations
    &.navbar-dark {
      background: theme-color(dark);

      .navbar-toggler {
        color: $white;
      }

      .count {
        border-color: theme-color(dark);
      }
    }

    &.navbar-primary {
      background: theme-color(primary);

      .navbar-menu-wrapper {
        .navbar-nav {
          .nav-item {
            .nav-link {
              &.count-indicator {
                .count {
                  background: theme-color(danger);
                  color: $white;
                }
              }
            }
          }
        }
      }
    }

    &.navbar-success {
      background: theme-color(success);

      .navbar-menu-wrapper {
        input {
          color: $white;

          @include placeholder {
            color: inherit;
          }
        }

        .navbar-nav {
          .nav-item {
            .nav-link {
              &.count-indicator {
                .count {
                  background: darken(theme-color(success), 30%);
                  color: $white;
                  border-color: theme-color(success);

                  &.bg-success {
                    background: darken(theme-color(success), 20%) !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.navbar-danger {
      background: theme-color(danger);

      .navbar-menu-wrapper {
        input {
          color: $white;

          @include placeholder {
            color: inherit;
          }
        }

        .navbar-nav {
          .nav-item {
            .nav-link {
              &.count-indicator {
                .count {
                  background: darken(theme-color(danger), 30%);
                  color: $white;
                  border-color: theme-color(danger);

                  &.bg-danger {
                    background: darken(theme-color(danger), 20%) !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.navbar-warning {
      background: theme-color(warning);

      .navbar-menu-wrapper {
        input {
          color: $white;

          @include placeholder {
            color: inherit;
          }
        }

        .navbar-nav {
          .nav-item {
            .nav-link {
              &.count-indicator {
                .count {
                  background: darken(theme-color(warning), 30%);
                  color: $white;
                  border-color: theme-color(warning);

                  &.bg-warning {
                    background: darken(theme-color(warning), 20%) !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.navbar-pink {
      background: color(pink);

      a {
        color: $white;
      }

      .navbar-toggler {
        color: $white;
      }

      .navbar-brand-wrapper {
        .navbar-brand {
          color: $white;
        }
      }

      .navbar-menu-wrapper {
        input {
          color: $white;

          @include placeholder {
            color: inherit;
          }
        }

        .navbar-nav {
          .nav-item {
            .nav-link {
              color: $white;

              &.count-indicator {
                .count {
                  background: darken(color(pink), 30%);
                  color: $white;
                  border-color: color(pink);

                  &.bg-pink {
                    background: darken(color(pink), 20%) !important;
                  }
                }
              }
            }

            &.dropdown {
              .navbar-dropdown {
                .dropdown-item {
                  color: $black;
                }
              }
            }
          }
        }
      }
    }

    &.navbar-info {
      background: theme-color(info);

      a {
        color: $white;
      }

      .navbar-toggler {
        color: $white;
      }

      .navbar-menu-wrapper {
        input {
          color: $white;

          @include placeholder {
            color: inherit;
          }
        }

        .navbar-nav {
          .nav-item {
            color: $white;

            .nav-link {
              &.count-indicator {
                .count {
                  background: darken(theme-color(info), 30%);
                  color: $white;
                  border-color: theme-color(info);

                  &.bg-info {
                    background: darken(theme-color(info), 20%) !important;
                  }
                }
              }
            }

            &.dropdown {
              .navbar-dropdown {
                .dropdown-item {
                  color: $black;
                }
              }
            }
          }
        }
      }
    }

    &.navbar-danger,
    &.navbar-dark,
    &.navbar-primary,
    &.navbar-success,
    &.navbar-warning {
      .navbar-brand-wrapper {
        .navbar-brand {
          color: $white;
        }
      }

      .navbar-menu-wrapper {
        .navbar-nav {
          .nav-item {
            .nav-link {
              color: $white;
            }
          }
        }
      }

      @extend .navbar-dark;
    }
  }
}

@media (max-width:991px) {
  .navbar {
    &.default-layout {
      flex-direction: row;

      .navbar-brand-wrapper {
        width: 75px;

        .brand-logo {
          display: none;
        }

        .brand-logo-mini {
          display: inline-block;
        }
      }
    }
  }

  .navbar-collapse {
    display: flex;
    margin-top: 0.5rem;
  }
}