/* Miscellanoeous */

body,
html {
  overflow-x: hidden;
  padding-right: 0 !important; // resets padding right added by Bootstrap modal
  // font-size: 0.75rem;
  background-color: $white;
}

html.dark-bg {
  @include rel;
  &::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    @include transition(.4s);
  }
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto !important;
}
.button__hide {
  display: none;
  &.active {
    display: block;
  }
}
.page-body-wrapper {
  // min-height: calc(100vh - #{$navbar-height});
  @include display-flex();
  @include flex-direction(row);
  padding-left: 0;
  padding-right: 0;
  &:not(.auth-page) {
    // padding-top: $navbar-height;
  }
  &.full-page-wrapper {
    width: 100%;
    min-height: 100vh;
  }
}


.card__columns {
  .row {
    flex-direction: row-reverse;
    -webkit-flex-direction: row-reverse;
  }
  .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
    width: 50%;
  }
  @media only screen and (max-width: 959px) {
    .row {
      flex-direction: row;
      -webkit-flex-direction: row;
    }
    .col-6 {
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.roles-edit-permissions {
  .alert {
    padding: 0.75rem 1.25rem !important;
  }
}

.footer__dashboard {
  margin: 20px 0 0;
  .container-fluid {
    max-width: 1355px;
  }
  &--block {
    background-color: $white;
    @include border-radius(5px);
    padding: 1.8rem 1.8rem 1.375rem;
    text-align: center;
    h2 {
      color: $brand-blue;
      font-size: 1.25rem;
      line-height: 1;
      margin: 0 0 1.25rem;
      font-weight: $f-black;
    }
    p {
      font-weight: $med;
      font-size: 1rem;
      line-height: 1.3;
      margin: 0 auto 1rem;
    }
    .btn span {
      text-transform: none;
      font-size: 1rem;
    }
  }
}

.no-pad {
  padding: 0 !important;
}

.rel {
  position: relative;
}

.left {
  float: left;
}

.right {
  float: right;
}

.no-pointer {
  pointer-events: none;
}
.card-header {
  &__white {
    background-color: $white;
    border: none;
  }
}
.card-body.card-header__white {
  form {
    padding: 0.75rem 1.25rem;
  }
}
.account-summary-block {
  margin: 25px auto 20px;
  max-width: 565px !important;
  padding: 25px !important;
  @include border-radius(5px);
  background-color: $white;
  span {
    line-height: 1;
    &.plus-minus {
      font-size: 60px;
      font-weight: $med;
    }
  }
  &__amount {
    &--price {
      font-size: 60px;
      color: $brand-blue;
      font-weight: $f-black;
    }
  }
  .btn {
    width: 100%;
    margin: 20px auto 0;
  }
  @media only screen and (max-width: 768px) {
    &__amount--price {
      font-size: 38px;
    }
  }
}

.card-title {
  white-space: unset;
}

.mb {
  margin-bottom: 20px;
}

.mt {
  margin-top: 10px;
}

.no-mar {
  margin: 0 !important;
}
//Upload images styling
.propImgs.f-size {
  font-size: 0;
  line-height: 1;
  text-align: center;
}

.images-tooltip {
  margin-bottom: 10px;
}

.images-outer {
  @include inline;
  vertical-align: middle;
  //width: 12%;
  //margin: 10px 1% 10px 0;
  //&:last-child {
  //  margin: 10px 0;
  //}
  .image-remove {
    font-size: 15px;
    float: left;
    width: 25px;
    @include rel;
    form {
      padding: 0;
    }
  }
  .images-inner {
    .smlImgwrap {
      width: calc(100% - 40px);
      height: auto;
      padding-right: 0;
      img {
        width: 165px;
        height: 100px;
      }
    }
  }
}

.tooltips {
  width: 200px;
  text-align: center;
  border: 1px solid $white;
  @include border-radius(5px);
  background-color: $brand-purple;
  color: $white;
  font-size: 1.25rem;
  display: none;
  padding: 8px;
  position: absolute;
  top: -100%;
  left: 0;
  margin-left: -100px;
  z-index: 8;
  @media only screen and (max-width: 768px) {
    margin-left: -130px;
  }
}

.tooltip__reveal {
  @include on-event {
    .tooltips {
      display: block !important;
    }
  }
}

.mr-5p {
  margin-right: 5px !important;
}

.shadow2 {
    @include shadow2;
}

.shadow {
    @include shadow;
}

.button-padding {
  padding-bottom: 80px !important;
}

.form__align--bottom {
    position: absolute;
    bottom: 10px;
    left: 0.65rem;
    display: block;
    width: calc(100% - 1.3rem);
    .btn {
      display: block;
    }
}

.pad-25 {
  padding: 0 25px;
}
.align__self {
  align-self: center;
}
.table-section {
    &__table {
        @include border-radius(5px);
        th,
        td {
            padding: 5px 10px;
            font-size: 16px;
        }
        &--header {
            background-color: $brand-blue;
            th {
                font-weight: $f-black;
                color: $white;
                border-right: 1px solid $brand-blue;
            }
        }
        &--body {
            background-color: $white;
            td {
                border: 1px solid #d4d4d4;
            }
        }
        &--price {
            color: $brand-blue;
            font-weight: $f-black;
        }
        .btn {
            padding: 2px;
        }
    }
}

.main-panel {
  transition: width $action-transition-duration $action-transition-timing-function, margin $action-transition-duration $action-transition-timing-function;
  width: 100%;
  min-height: calc(100vh - 112px);
  @include display-flex();
  @include flex-direction(column);
  @media (max-width: 991px) {
    margin-left: 0;

  }
}

.content-wrapper {
  // background: $content-bg;
  padding: $content-padding-y $content-padding-x;
  width: 100%;
  @include flex-grow(1);
}

.container-scroller {
  overflow: hidden;
}

.scroll-container {
  position: relative;
  &.horizontally {
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;
  }
  &.vertically {
    overflow-y: hidden;
    height: 100%;
    max-height: 100%;
  }
}

pre {
  background: color(gray-lighter);
  padding: 15px;
  font-size: 14px;
}

.visible-all-inline {
  display: inline !important;
}

.parsley-errors-list {
  margin: 0.5rem 0rem 0rem 0rem !important;
}

#propImg {
  width: 100%;
  text-align: center;
  &:before, &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 1px;
  }
  img {
    display: inline-block;
    vertical-align: middle;
    max-width: 900px;
    height: 500px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

img.propImgsml {
    cursor: pointer;

    width: 80px;
    height: 60px;


}

.propImgs {
  padding-top: 10px;
  padding-bottom:10px;

  > .smlImgwrap {
    width: 13%;
    height: auto;
  }
  .smlImgwrap {
    float: left;
    padding-right: 10px;
    position: relative;
    overflow: hidden;
    &:after {
      content: "\f002";
      color: #fff;
      background: rgba(8, 120, 202, 0.8);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      font-family: 'Font Awesome 5 pro';
      font-size: 2em;
      padding-top: 20px;
      text-align: center;
      padding-left: 10px;
      opacity: 0;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
    }
    &:last-of-type {
      padding-right: 0px;
      &:after {
        left: 0;
        padding-left: 0px;
      }
    }


    .propImgsml {
      &.selection {
        &:after {
          content: "\e6a4";
          //font-family: 'Stroke-Gap-Icons';
          line-height: 80px;
          text-align: center;
          color: #fff;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: #0878ca;
          z-index: 10;
          font-size: 32px;
          opacity: 0;
          transition: all 0.6s;
        }
      }
    }
    &:hover {
      cursor: pointer;
      &:after {
        opacity: 1;
        cursor: pointer;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
      }
    }
  }
}

#map {
  height: 350px;
  width: 100%;
}

.map {
  height: 350px;
  width: 100%;
}

.loader {
  margin: 50px auto;
  font-size: 7px;
  width: 5px;
  height: 5px;
  @include border-radius(50%);
  @include rel;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em $brand-blue, 1.8em -1.8em 0 0em rgba(81,172,210, 0.2), 2.5em 0em 0 0em rgba(81,172,210, 0.2), 1.75em 1.75em 0 0em rgba(81,172,210, 0.2), 0em 2.5em 0 0em rgba(81,172,210, 0.2), -1.8em 1.8em 0 0em rgba(81,172,210, 0.2), -2.6em 0em 0 0em rgba(81,172,210, 0.5), -1.8em -1.8em 0 0em rgba(81,172,210, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(81,172,210, 0.7), 1.8em -1.8em 0 0em $brand-blue, 2.5em 0em 0 0em rgba(81,172,210, 0.2), 1.75em 1.75em 0 0em rgba(81,172,210, 0.2), 0em 2.5em 0 0em rgba(81,172,210, 0.2), -1.8em 1.8em 0 0em rgba(81,172,210, 0.2), -2.6em 0em 0 0em rgba(81,172,210, 0.2), -1.8em -1.8em 0 0em rgba(81,172,210, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(81,172,210, 0.5), 1.8em -1.8em 0 0em rgba(81,172,210, 0.7), 2.5em 0em 0 0em $brand-blue, 1.75em 1.75em 0 0em rgba(81,172,210, 0.2), 0em 2.5em 0 0em rgba(81,172,210, 0.2), -1.8em 1.8em 0 0em rgba(81,172,210, 0.2), -2.6em 0em 0 0em rgba(81,172,210, 0.2), -1.8em -1.8em 0 0em rgba(81,172,210, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(81,172,210, 0.2), 1.8em -1.8em 0 0em rgba(81,172,210, 0.5), 2.5em 0em 0 0em rgba(81,172,210, 0.7), 1.75em 1.75em 0 0em $brand-blue, 0em 2.5em 0 0em rgba(81,172,210, 0.2), -1.8em 1.8em 0 0em rgba(81,172,210, 0.2), -2.6em 0em 0 0em rgba(81,172,210, 0.2), -1.8em -1.8em 0 0em rgba(81,172,210, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(81,172,210, 0.2), 1.8em -1.8em 0 0em rgba(81,172,210, 0.2), 2.5em 0em 0 0em rgba(81,172,210, 0.5), 1.75em 1.75em 0 0em rgba(81,172,210, 0.7), 0em 2.5em 0 0em $brand-blue, -1.8em 1.8em 0 0em rgba(81,172,210, 0.2), -2.6em 0em 0 0em rgba(81,172,210, 0.2), -1.8em -1.8em 0 0em rgba(81,172,210, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(81,172,210, 0.2), 1.8em -1.8em 0 0em rgba(81,172,210, 0.2), 2.5em 0em 0 0em rgba(81,172,210, 0.2), 1.75em 1.75em 0 0em rgba(81,172,210, 0.5), 0em 2.5em 0 0em rgba(81,172,210, 0.7), -1.8em 1.8em 0 0em $brand-blue, -2.6em 0em 0 0em rgba(81,172,210, 0.2), -1.8em -1.8em 0 0em rgba(81,172,210, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(81,172,210, 0.2), 1.8em -1.8em 0 0em rgba(81,172,210, 0.2), 2.5em 0em 0 0em rgba(81,172,210, 0.2), 1.75em 1.75em 0 0em rgba(81,172,210, 0.2), 0em 2.5em 0 0em rgba(81,172,210, 0.5), -1.8em 1.8em 0 0em rgba(81,172,210, 0.7), -2.6em 0em 0 0em $brand-blue, -1.8em -1.8em 0 0em rgba(81,172,210, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(81,172,210, 0.2), 1.8em -1.8em 0 0em rgba(81,172,210, 0.2), 2.5em 0em 0 0em rgba(81,172,210, 0.2), 1.75em 1.75em 0 0em rgba(81,172,210, 0.2), 0em 2.5em 0 0em rgba(81,172,210, 0.2), -1.8em 1.8em 0 0em rgba(81,172,210, 0.5), -2.6em 0em 0 0em rgba(81,172,210, 0.7), -1.8em -1.8em 0 0em $brand-blue;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em $brand-blue, 1.8em -1.8em 0 0em rgba(81,172,210, 0.2), 2.5em 0em 0 0em rgba(81,172,210, 0.2), 1.75em 1.75em 0 0em rgba(81,172,210, 0.2), 0em 2.5em 0 0em rgba(81,172,210, 0.2), -1.8em 1.8em 0 0em rgba(81,172,210, 0.2), -2.6em 0em 0 0em rgba(81,172,210, 0.5), -1.8em -1.8em 0 0em rgba(81,172,210, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(81,172,210, 0.7), 1.8em -1.8em 0 0em $brand-blue, 2.5em 0em 0 0em rgba(81,172,210, 0.2), 1.75em 1.75em 0 0em rgba(81,172,210, 0.2), 0em 2.5em 0 0em rgba(81,172,210, 0.2), -1.8em 1.8em 0 0em rgba(81,172,210, 0.2), -2.6em 0em 0 0em rgba(81,172,210, 0.2), -1.8em -1.8em 0 0em rgba(81,172,210, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(81,172,210, 0.5), 1.8em -1.8em 0 0em rgba(81,172,210, 0.7), 2.5em 0em 0 0em $brand-blue, 1.75em 1.75em 0 0em rgba(81,172,210, 0.2), 0em 2.5em 0 0em rgba(81,172,210, 0.2), -1.8em 1.8em 0 0em rgba(81,172,210, 0.2), -2.6em 0em 0 0em rgba(81,172,210, 0.2), -1.8em -1.8em 0 0em rgba(81,172,210, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(81,172,210, 0.2), 1.8em -1.8em 0 0em rgba(81,172,210, 0.5), 2.5em 0em 0 0em rgba(81,172,210, 0.7), 1.75em 1.75em 0 0em $brand-blue, 0em 2.5em 0 0em rgba(81,172,210, 0.2), -1.8em 1.8em 0 0em rgba(81,172,210, 0.2), -2.6em 0em 0 0em rgba(81,172,210, 0.2), -1.8em -1.8em 0 0em rgba(81,172,210, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(81,172,210, 0.2), 1.8em -1.8em 0 0em rgba(81,172,210, 0.2), 2.5em 0em 0 0em rgba(81,172,210, 0.5), 1.75em 1.75em 0 0em rgba(81,172,210, 0.7), 0em 2.5em 0 0em $brand-blue, -1.8em 1.8em 0 0em rgba(81,172,210, 0.2), -2.6em 0em 0 0em rgba(81,172,210, 0.2), -1.8em -1.8em 0 0em rgba(81,172,210, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(81,172,210, 0.2), 1.8em -1.8em 0 0em rgba(81,172,210, 0.2), 2.5em 0em 0 0em rgba(81,172,210, 0.2), 1.75em 1.75em 0 0em rgba(81,172,210, 0.5), 0em 2.5em 0 0em rgba(81,172,210, 0.7), -1.8em 1.8em 0 0em $brand-blue, -2.6em 0em 0 0em rgba(81,172,210, 0.2), -1.8em -1.8em 0 0em rgba(81,172,210, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(81,172,210, 0.2), 1.8em -1.8em 0 0em rgba(81,172,210, 0.2), 2.5em 0em 0 0em rgba(81,172,210, 0.2), 1.75em 1.75em 0 0em rgba(81,172,210, 0.2), 0em 2.5em 0 0em rgba(81,172,210, 0.5), -1.8em 1.8em 0 0em rgba(81,172,210, 0.7), -2.6em 0em 0 0em $brand-blue, -1.8em -1.8em 0 0em rgba(81,172,210, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(81,172,210, 0.2), 1.8em -1.8em 0 0em rgba(81,172,210, 0.2), 2.5em 0em 0 0em rgba(81,172,210, 0.2), 1.75em 1.75em 0 0em rgba(81,172,210, 0.2), 0em 2.5em 0 0em rgba(81,172,210, 0.2), -1.8em 1.8em 0 0em rgba(81,172,210, 0.5), -2.6em 0em 0 0em rgba(81,172,210, 0.7), -1.8em -1.8em 0 0em $brand-blue;
  }
}


