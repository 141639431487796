/* Cards */
.card {
  border: 0;
  border-radius: 0.25rem;
  background-color: transparent !important;

  .card-body {
    // padding: $card-padding-y $card-padding-x;
    padding: 10px 0;
    @media only screen and (max-width: 959px) {
      padding: 0;
    }

    + .card-body {
      padding-top: 0;
    }
  }

  .card-buttons {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: #ffffff;
    text-transform: capitalize;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    white-space: nowrap;
  }
  .form-group .col-6 {
    flex: 0 0 100%;
  }
  &.card-outline-success {
    border: 1px solid theme-color("success");
  }

  &.card-outline-primary {
    border: 1px solid theme-color("primary");
  }

  &.card-outline-warning {
    border: 1px solid theme-color("warning");
  }

  &.card-outline-danger {
    border: 1px solid theme-color("danger");
  }

  &.card-rounded {
    @include border-radius(5px);
  }

  &.card-faded {
    background: #b5b0b2;
    border-color: #b5b0b2;
  }

  &.card-circle-progress {
    color: $white;
    text-align: center;
  }

  &--dashboard {
    background: transparent !important;
    background-color: transparent !important;
    padding: 1rem 0px 0px 0px !important;
    .card-body {
      background: transparent !important;
      background-color: transparent !important;
      padding: 0px !important;
    }
  }

}
@each $color, $value in $theme-colors {
  .card-inverse-#{$color} {
    @include card-inverse-variant(rgba(theme-color($color), .2), theme-color-level($color, 1), theme-color-level($color, 3));
  }
}
