.block-tiles.row {
  margin: 0 -12.5px 25px;

  .col {
    padding: 0;
    width: 24.25%;
    margin: 0 1% 0 0;

    &:last-child {
      margin: 0;
    }
  }

  &.block-tiles-margin {
    margin: 0 0 30px;
  }

  @media only screen and (max-width: 959px) {
    margin: 0 auto;
    // flex-direction: column;
    display: block;
    .col-3,
    .col-2 {
      max-width: 100%;
    }
    .block-tile__colours svg {
      font-size: 80px;
    }
  }
}

.border__blue {
  border: 1px solid $brand-blue;
  h5 {
    margin: 0 0 0.9375rem;
    font-size: 1.125rem;
  }
  > svg {
    color: $brand-blue !important;
  }
}

.btn-border {
  &__orange {
    border: 1px solid $brand-orange;
  }
}

.block-tile {
  padding: 1.3rem;
  text-align: center;
  @include border-radius(5px);
  @include inline;
  @include transition(.4s);
  font-size: 0;
  width: 100%;
  overflow: hidden;
  margin: 0 0 20px;
  //-webkit-box-shadow: 0px 0px 5px 0px rgba(75, 79, 109, 0.5);
  //-moz-box-shadow: 0px 0px 5px 0px rgba(75, 79, 109, 0.5);
  //box-shadow: 0px 0px 5px 0px rgba(75, 79, 109, 0.5);
  @include on-event {
    p {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  p {
    //opacity: 0;
    //transform: translate(0, 50px);
    //transition: opacity 1s ease-in-out, transform 0.4s ease-in-out;
    margin: 0;
    color: $white;
    line-height: 1.2;
  }

  &__colours--black {
    background-color: #000;
  }

  &__colours--white {
    background-color: #FFF !important;

    span {
      color: #000 !important;
    }
  }


  &__account {

    .icon__static {
      position: static;
      width: auto !important;
      height: 80% !important;
      color: $brand-green;
    }
    .block-tile__cta--numbers {
      font-size: 1.3rem !important;
      top: auto !important;
      bottom: 0 !important;
      transform: translate(-50%, 0) !important;
    }
  }

  &__account_upgrade {

    .icon__static {
      position: static;
      width: auto !important;
      height: 80% !important;
      color: $yellow;
    }
    .block-tile__cta--numbers {
      font-size: 22px !important;
      top: auto !important;
      bottom: 0 !important;
      transform: translate(-50%, 0) !important;
    }
  }


  &--link-wrap {
    @include on-event {
      text-decoration: none;
    }
  }

  span.fa-layers {
    position: static;
  }

  a {
    font-size: 0.75rem;
  }

  .btn {
    font-size: 1rem;
    padding: 1rem;
    width: 100%;
    &__download {
      border: none !important;
      padding: 0px;
      &:hover {
        background: inherit !important;
        box-shadow: none !important;
      }
    }
  }

  &__cta {
    &--error {
      &:hover {
        pointer-events: none;
      }
    }
    background-color: $white;
    //-webkit-box-shadow: 0px 0px 5px 0px rgba(75, 79, 109, 0.5);
    //-moz-box-shadow: 0px 0px 5px 0px rgba(75, 79, 109, 0.5);
    //box-shadow: 0px 0px 5px 0px rgba(75, 79, 109, 0.5);
    @include rel;
    padding: 1.3rem 0.5rem 0.5rem 0.5rem;
    .btn span {
      word-break: break-all;
    }
    h4 {
      font-size: 1.25rem;
      line-height: 1;
      margin: 0px;
      text-transform: uppercase;
      font-weight: $f-black;
      color: $brand-blue;
      min-height: 40px;
    }

    a.icon {
      .fa-layers {
        svg.svg-inline--fa {
          margin: 0 auto;
          right: 0;
          left: 0;
          top: auto;
          bottom: auto;
        }
        svg.outline {
          color: $brand-grey !important;
        }
      }
      &--blue {
        color: $brand-blue;
        @include on-event{
          color: $brand-blue;
        }
      }

      &--orange {
        color: $brand-orange;
        @include on-event{
          color: $brand-orange;
        }
      }

      &--purple {
        color: $brand-purple;
        @include on-event{
          color: $brand-purple;
        }
      }

      &--red {
        color: $brand-red;
        @include on-event{
          color: $brand-red;
        }

      }

      &--green {
        color: $brand-green;
        @include on-event{
          color: $brand-green;
        }

      }

      &--widget-green {
        color: $brand-dark-green;
        @include on-event{
          color: $brand-dark-green;
        }

      }

      &--dark-green {
        color: $brand-dark-green;
        @include on-event{
          color: $brand-dark-green;
        }

      }

      &--white {
        color: $white;
        @include on-event{
          color: $white;
        }
        svg.fa-inverse {
          color: $black !important;
          @include on-event{
            color: $black;
          }
        }
      }

      &--body-blue {
        color: $body-blue;
        @include on-event{
          color: $body-blue;
        }
      }
    }


    &--numbers {
      font-size: 2.5rem;
      line-height: 1;
      margin: 1.7rem auto 1.7rem;
      font-weight: $f-black;
      color: $brand-purple;

      &--blue {
        color: $brand-blue;
      }

      &--orange {
        color: $brand-orange;
      }

      &--purple {
        color: $brand-purple;
      }

      &--red {
        color: $brand-red;
      }

      &--green {
        color: $brand-green;
      }

      &--widget-green {
        color: $brand-dark-green;
      }

      &--dark-green {
        color: $brand-dark-green;
      }

      &--white {
        color: $white;
      }

      &--body-blue {
        color: $body-blue;
      }
    }

    &--account {
      .block-tile__cta--numbers {
        color: $brand-blue;
      }
    }

    h5,
    .no-btn {
      margin: 0 0 0.9375rem;
      font-size: 1.125rem;
    }

    .currency {
      font-size: 40px;
      color: $main-font;
    }

    .no-btn {
      display: block;
      text-decoration: underline !important;
      color: $main-font !important;
      @include on-event {
        text-decoration: none;
      }
      margin: 0 0 0.9375rem;
      font-size: 1.3rem;
      &--red {
        color: $brand-red !important;
      }
      &--green {
        color: $brand-green !important;
      }
    }

    &--notification--wrapper {
      @include rel;
      height: 4.5rem;
      width: 4.5rem;
      @include border-radius(50%);
      display: block;
      margin: 10px auto 10px;
      text-align: center;

      &--white {
        width: 100%;
      }

      &--blue {
        background-color: $brand-blue;
      }

      &--yellow {
        background-color: $yellow;
      }

      &--orange {
        background-color: $brand-orange;
      }

      &--purple {
        background-color: $brand-purple;
      }

      &--red {
        background-color: $brand-red;
      }

      &--green {
        background-color: $brand-green;
      }

      &--dark-green {
        background-color: $brand-dark-green;
      }

      &--white {
        background-color: $white;
      }

      &--body-blue {
        background-color: $body-blue;
      }

      .block-tile__cta--numbers {
        @include rel;
        background-color: transparent;
        font-size: 37px;
        color: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0px;
        width: 100%;

        &--blue {
          color: $brand-blue;
        }

        &--orange {
          color: $brand-orange;
        }

        &--purple {
          color: $brand-purple;
        }

        &--red {
          color: $brand-red;
        }

        &--green {
          color: $brand-green;
        }

        &--dark-green {
          color: $brand-dark-green;
        }

        &--white {
          color: $white;
        }

        &--body-blue {
          color: $body-blue;
        }
      }

      svg {
        //position: absolute;
        //top: 0.15rem;
        //left: -0.5rem;
        //height: 1.75rem !important;
        //width: 1.75rem !important;
        //font-size: 1.5rem !important;
        //background-color: inherit;
        //@include border-radius(50%);
        //color: $white;
        //padding: 5px;
        //border: 2px solid white;
      }
    }
  }

  h4.block-tile--error {
    font-size: 1.1rem;
    margin-top: 18px;
    color: $brand-blue;
  }

  &__colours {
    &.no-pointer {
      box-shadow: none;
    }
    svg {
      color: $white;
      margin: 0 0 10px;
    }

    h1 {
      color: $white;
      font-size: 50px;
      line-height: 1;
      margin: 0;
      font-weight: $f-black;
    }

    h4 {
      color: $white;
      font-size: 1.3rem;
      line-height: 1;
      margin: 0 0 30px;
      font-weight: $med;
      overflow-wrap: initial;
      &.block-tile--title {
        font-size: 2.2rem;
        color: $brand-blue;
      }
    }

    h6 {
      color: $white;
      line-height: 1;
      font-weight: $reg;

      a {
        text-decoration: underline;
        color: $white;
      }

    }

    &--blue {
      background: $brand-blue;
      @include on-event {
        background: darken($brand-blue, 10%)
      }

      h1 {
        color: $brand-blue;
      }

      span.block-tile__cta--numbers {
        color: $brand-blue;
      }
    }

    &--yellow {
      background: $yellow;
      @include on-event {
        background: darken($yellow, 10%)
      }

      h1 {
        color: $yellow;
      }

      span.block-tile__cta--numbers {
        color: $yellow;
      }
    }

    &--orange {
      background: $brand-orange;
      @include on-event {
        background: darken($brand-orange, 10%)
      }

      h1 {
        color: $brand-orange;
      }

      span.block-tile__cta--numbers {
        color: $brand-orange;
      }
    }

    &--purple {
      background: $brand-purple;
      @include on-event {
        background: darken($brand-purple, 10%)
      }

      h1 {
        color: $brand-purple;
      }

      span.block-tile__cta--numbers {
        color: $brand-purple;
      }
    }

    &--red {
      background: $brand-red;
      @include on-event {
        background: darken($brand-red, 10%)
      }

      h1 {
        color: $brand-red;
      }

      span.block-tile__cta--numbers {
        color: $brand-red;
      }
    }

    &--green {
      background: $brand-green;
      @include on-event {
        background: darken($brand-green, 10%)
      }

      h1 {
        color: $brand-green;
      }

      span.block-tile__cta--numbers {
        color: $brand-green;
      }
    }

    &--widget-green {
      background: $brand-dark-green;
      @include on-event {
        background: darken($brand-dark-green, 10%)
      }

      h1 {
        color: $brand-dark-green;
      }

      span.block-tile__cta--numbers {
        color: $brand-dark-green;
      }
    }


    &--dark-green {
      background: $brand-dark-green;
      @include on-event {
        background: darken($brand-dark-green, 10%)
      }

      h1 {
        color: $brand-dark-green;
      }

      span.block-tile__cta--numbers {
        color: $brand-dark-green;
      }
    }

    &--white {
      background: $brand-white;

      h1 {
        color: $brand-grey;
      }

      h4 {
        color: $brand-grey;
        text-transform: uppercase;
      }

      h6 {
        color: $brand-grey;

        a {
          color: $brand-grey;
          text-decoration: underline;
        }
      }

      p {
        color: $brand-grey;
      }
    }

    &--body-blue {
      background: $body-blue;

      &.no-pointer {
        h4 {
          margin: 0 0 10px !important;
        }
      }

      @include on-event {
        background: darken($body-blue, 10%)
      }

      h1 {
        color: $body-blue;
      }

      h4 {
        color: $brand-grey;
      }

      span.block-tile__cta--numbers {
        color: $body-blue;
      }
    }
  }
}

.property-block {
  @include border-radius(5px);
  margin: 0 0 25px;
  width: 100%;
  //-webkit-box-shadow: 0px 0px 5px 0px rgba(75, 79, 109, 0.5);
  //-moz-box-shadow: 0px 0px 5px 0px rgba(75, 79, 109, 0.5);
  //box-shadow: 0px 0px 5px 0px rgba(75, 79, 109, 0.5);

  img {
    // padding: 0.5rem;
    &#profile {
      display: block;
      max-width: 100%;
      //max-height: 100%;
      margin: 0 auto;
    }
  }

  &__content {
    padding: 1.3rem;
    background-color: $white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    label {
      font-size: 1.125rem;
      color: $brand-blue;
    }

    h4 {
      font-size: 1.25rem;
      line-height: 1;
      color: $brand-blue;
      text-transform: uppercase;
      font-weight: $semi;
    }

    &--blocks {
      margin: 0 0 10px;

      h4 {
        font-size: 1rem;
        line-height: 1;
        color: $brand-blue;
        text-decoration: underline;
        text-transform: uppercase;
        font-weight: $semi;
      }

      p {
        line-height: 20px;
        margin: 0px;
      }
    }
  }

  img {
    max-height: 100%;
    max-width: 100%;
    background: $white;
  }
}

.image-div {
    height: 310px;
    overflow: hidden;
    text-align: center;
  }
.image-div,
.image-holder {
  padding: 10px;
}

.image-holder {
  text-align: center;
  height: 80px;

}
@media only screen and (max-width: 1370px) {
  .image-div {
    max-height: 200px;
  }
}
@media only screen and (max-width: 479px) {
  .image-div {
    max-height: 170px;
    padding: 0;
  }
}
